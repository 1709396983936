import _ from 'lodash-es'

const BaseItem = {
  rg_id: null,
  slug: null,
  title: null,
  year: null,
  grouped_availability: [],
  ungrouped_availability: []
}

const defaultPartialData = partial => {
  const keys = Object.keys(partial)

  return Object.assign(
    data => _.cloneDeep(
      _.omitBy(
        _.pick(
          _.defaults(data, partial),
          keys
        ),
        _.isNil
      )
    ),
    {
      defaults: partial
    }
  )
}

export const Movie = defaultPartialData({
  ...BaseItem,
  type: 'movie'
})

export const Show = defaultPartialData({
  ...BaseItem,
  type: 'show',
  seasons: [],
  season_data: {}
})

const SimpleBaseItem = {
  id: undefined,
  number: 0,
  grouped_availability: [],
  ungrouped_availability: []
}

export const Season = defaultPartialData({
  ...SimpleBaseItem,
  episodes: []
})

export const Episode = defaultPartialData({
  ...SimpleBaseItem,
  title: ''
})

const BaseServiceItem = {
  name: '',
  id: 0,
  quantity: undefined,
  logo_slug: '',
  pricing: {
    rent: undefined,
    buy: undefined
  }
}

export const ServiceGroup = defaultPartialData({
  ...BaseServiceItem,
  access_types: [],
  services: []
})

export const Service = defaultPartialData({
  ...BaseServiceItem,
  access_type: 0,
  group_id: 0
})
