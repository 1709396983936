require('preact-cli/lib/lib/webpack/polyfills') // eslint-disable-line no-undef
require('~styles/fonts.scss') // eslint-disable-line no-undef

import habitat from 'preact-habitat'

import createApp from './app'
import createStore from './store'

const store = createStore()
const App = createApp(store)

habitat(App).render({
  selector: '[data-widget-host="reelgood-pub"]',
  clean: true
})
