import Facet from '@reelgood/js-sdk/es/facets/facet'
import _ from 'lodash-es'

export default class CORSFacet extends Facet {
  constructor(apiKey, prod = true, ...args) {
    super(...args)

    Object.defineProperty(this, 'parterAPIUrl', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: prod ? 'https://partner-api.reelgood.com/v1.0/widget' : 'https://staging-partner-api.reelgood.com/v1.0/widget'
    })

    Object.defineProperty(this, 'linkingUrl', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: prod ? 'https://linker.reelgood.com' : 'https://staging-linker.reelgood.com'
    })

    Object.defineProperty(this, 'apiKey', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: apiKey
    })

    Object.defineProperty(this, 'corsTransport', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: (url, params = {}, auth) => {
        _.set(params, 'headers.x-api-key', apiKey)

        return this.transport(url, params, auth)
      }
    })
  }
}
