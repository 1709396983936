import PropTypes from 'prop-types'
import styles from './styles.scss'
import classnames from 'classnames'

export default function RowServicePlatform({ id, display_name, quantity, quantityLabel, pricingLabel, buyLabel }) {
  const {
    appearance: {
      general: { theme },
      popup: {
        services: { platform_text_color, arrow_icon_color }
      }
    }
  } = this.context.settings

  return (
    <div
      className={classnames(styles.service_wrapper, styles[theme])}
      onClick={this.props.itemSelected}
      role='button'
      tabIndex='0'
    >
      <div className={styles.labels}>
        <span
          className={styles.service_title}
          style={{
            color: platform_text_color
          }}
        >
          {display_name}
        </span>
      </div>

      <div className={styles.arrow}>
        <svg viewBox="6 -3.5 10 19" stroke={arrow_icon_color} stroke-width="2" fill="none" fill-rule="evenodd">
          <polyline id="Path-15-Copy-11" transform="rotate(-45.000000)" points="-5.485281 15.485281 6.315495 14.315495 7.485281 3.485281" />
        </svg>
      </div>
    </div>
  )
}

RowServicePlatform.propTypes = {
  id: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  itemSelected: PropTypes.func
}
