import _ from 'lodash-es'
import { showOpenSuccess, SHOW_OPEN_FAIL } from '~actions/show'
import { seasonLoadSuccess, SEASON_LOAD_FAIL } from '~actions/season'
import { addEntities } from '~actions/entities'
import { epic } from '@reelgood/redux'
import { catchError, map } from 'rxjs/operators'
import { constructStateId } from '~lib/state'
import { entityByStateId } from '~selectors/entities'
import { normalizeEntity } from './helpers'

export const onShowOpen = epic({
  key: 'onShowOpen',
  onActions: ['SHOW_OPEN'],
  callback: function onShowLoad({ payload: { id, type } }, state$, { sdk }) {
    if (type !== 'show') {
      return void 0
    }

    const state = state$.value

    return (
      sdk[`js_${type}`].popup(...[id].filter(Boolean)).pipe(
        map((entity) => {
          const parentStateId = constructStateId(type.replace('season', 'show'), entity.id)
          const oldEntity = entityByStateId(state, parentStateId)[1]

          const entities = {
            [parentStateId]: {
              ...normalizeEntity[type]({
                ...oldEntity,
                ...entity
              })
            }
          }

          const entityMeta = {
            [parentStateId]: {
              loadState: type === 'complete'
            }
          }

          return [
            addEntities(entities, entityMeta),
            showOpenSuccess(type, id)
          ]
        }),
        catchError(err => {
          console.error('show open failed!', err)
          return [{ type: SHOW_OPEN_FAIL, payload: { type, id, code: err.statusCode } }]
        })
      )
    )
  }
})

export const onSeasonLoad = epic({
  key: 'onSeasonLoad',
  onActions: ['SEASON_LOAD'],
  callback: function onSeasonLoad({ payload: { type, id, season } }, state$, { sdk }) {
    const state = state$.value

    return (
      sdk[`js_${type}`].season(...[id, season].filter(Boolean)).pipe(
        map((entity) => {
          const parentStateId = constructStateId(type.replace('season', 'show'), id)
          const oldEntity = entityByStateId(state, parentStateId)[1]

          const entities = {
            [parentStateId]: {
              ...oldEntity,
              season_data: {
                ...oldEntity.season_data,
                [season]: normalizeEntity.season({
                  ...entity
                })
              }
            }
          }

          const entityMeta = {
            [parentStateId]: {
              loadState: type === 'complete'
            }
          }

          return [
            addEntities(entities, entityMeta),
            seasonLoadSuccess(id, season)
          ]
        }),
        catchError(err => {
          console.error('show open failed!', err)
          return [{ type: SEASON_LOAD_FAIL, payload: { type, id, season, code: err.statusCode } }]
        })
      )
    )
  }
})
