import PropTypes from 'prop-types'
import styles from './styles.scss'
import MissingIcon from '~components/services/missingIcon'
import classnames from 'classnames'

export default function InlineService({ id, access_type, service_title, logo_slug }) {
  const {
    appearance: {
      sub_text: { text_visible, color },
      services: {
        use_service_icons, text_color, platform_text_color, stroke_color, round_service_icons,
        border_color, border_weight, border_style, hover_animation, width: service_width
      }
    },
    behavior: {
      services: { group_services }
    }
  } = this.context.settings

  const defaultWidth = 96
  const newFontSize = Math.floor(12 * (service_width / defaultWidth))
  const newBorderRadius = Math.floor(8 * (service_width / defaultWidth))
  const aspectRatio = defaultWidth / 46
  const newServiceWidth = service_width - (border_weight * 2)
  const newServiceHeight = newServiceWidth / aspectRatio
  return (
    <div
      className={classnames(styles.service_wrapper, styles[hover_animation])}
      onClick={this.props.itemSelected}
      role='button'
      tabIndex='0'
    >
      {use_service_icons && (
        logo_slug
          ? (
            <img
              src={`https://img.reelgood.com/service-logos/${logo_slug}@x1.png`}
              className={styles.service_icon}
              alt={id}
              key={id}
              style={{
                borderRadius: (round_service_icons && '6px') || null,
                borderColor: (border_weight && border_color) || null,
                borderWidth: (border_weight && `${border_weight}px`) || null,
                borderStyle: (border_weight && border_style) || null,
                width: `${newServiceWidth}px`,
                height: `${newServiceHeight}px`
              }}
            />
          )
          : <MissingIcon className={styles.service_icon} title={service_title} />
      )}

      {use_service_icons && !group_services && (
        <span
          className={styles.service_platform}
          style={{
            color: platform_text_color,
            maxWidth: newServiceWidth,
            fontSize: newFontSize
          }}
        >
          {service_title}
        </span>
      )}

      {text_visible && (group_services || !use_service_icons) && (
        <span
          className={classnames(styles.service_subtitle, { [styles.no_service_icons]: !use_service_icons })}
          style={{
            maxWidth: (use_service_icons && '96px') || null,
            color: use_service_icons ? color : text_color,
            backgroundColor: stroke_color,
            borderRadius: (!use_service_icons && round_service_icons && `${newBorderRadius}px`) || null,
            padding: (!use_service_icons && '0 8px 0') || null,
            fontSize: newFontSize
          }}
        >
          {use_service_icons ? access_type : service_title}
        </span>
      )}
    </div>
  )
}

InlineService.propTypes = {
  id: PropTypes.string.isRequired,
  access_type: PropTypes.string,
  service_title: PropTypes.string.isRequired,
  imageHidden: PropTypes.bool,
  itemSelected: PropTypes.func
}

InlineService.defaultProps = {
  imageHidden: false
}
