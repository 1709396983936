
export const initializeState = () => {
  return {}
}

export const loadState = () => {
  try {
    let serializedState = localStorage.getItem('com.reelgood.publishers_widget:state')
    if (serializedState === null) {
      return initializeState()
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return initializeState()
  }
}

export const saveState = (state) => {
  try {
    let serializedState = JSON.stringify(state)
    localStorage.setItem('com.reelgood.publishers_widget:state', serializedState)
  } catch (err) {}
}
