import { Show, Movie, Season, Episode, Service, ServiceGroup } from '~records'
import _ from 'lodash-es'

export const normalizeService = {
  default: data => Service({
    name: data.service_display_name,
    access_type: data.access_type,
    id: data.service_id,
    logo_slug: data.service_group_logo_slug,
    group_id: data.service_group_id,
    service_display_name: data.service_display_name,
    pricing: {
      buy: data.purchase_cost_sd || data.purchase_cost_hd,
      rent: data.rental_cost_sd || data.rental_cost_hd
    }
  }),
  show: data => Service({
    name: data.display_name || _.get(data, 'service.display_name'),
    id: data.id || _.get(data, 'service.id'),
    logo_slug: data.logo_slug || _.get(data, 'service.logo_slug'),
    access_type: data.access_type || _.get(data, 'service.access_type'),
    group_id: data.group_id || _.get(data, 'service.group_id'),
    quantity: data.count
  })
}

export const normalizeServiceGroups = {
  default: data => ServiceGroup({
    name: data.service_group_display_name,
    id: data.service_group_id,
    logo_slug: data.service_group_logo_slug,
    access_types: data.access_types,
    services: [],
    pricing: {
      rent: data.rental_cost_sd || data.rental_cost_hd,
      buy: data.purchase_cost_sd || data.purchase_cost_hd
    }
  }),
  show: data => ServiceGroup({
    name: data.display_name || _.get(data, 'service_group.name'),
    id: data.id || _.get(data, 'service_group.id'),
    logo_slug: data.logo_slug || _.get(data, 'service_group.logo_slug'),
    access_types: _.uniq(_.flatMap(data.services || _.get(data, 'service_group.services'), 'access_type')),
    services: _.map(data.services, normalizeService.show),
    quantity: data.count
  }),
  season: data => ServiceGroup({
    name: _.get(data, 'service_group.name'),
    id: _.get(data, 'service_group.id'),
    logo_slug: _.get(data, 'service_group.logo_slug'),
    services: _.map(data.services, normalizeService.show),
    access_types: _.get(data, 'service_group.access_types'),
    quantity: data.count
  })
}

export const normalizeEntity = {
  movie: data => Movie({
    ...data,
    rg_id: data.id,
    grouped_availability: _.map(data.grouped_availability, normalizeServiceGroups.default),
    ungrouped_availability: _.map(data.ungrouped_availability, normalizeService.default)
  }),
  show: data => Show({
    ...data,
    rg_id: data.id,
    grouped_availability: _.map(data.season_grouped_availability || data.grouped_availability, normalizeServiceGroups.show),
    ungrouped_availability: _.map(data.season_ungrouped_availability || data.ungrouped_availability, normalizeService.show)
  }),
  season: data => Season({
    ...data,
    grouped_availability: _.map(data.episode_grouped_availability, normalizeServiceGroups.season),
    ungrouped_availability: _.map(data.episode_ungrouped_availability, normalizeService.show),
    episodes: _.map(data.episodes, normalizeEntity.episode)
  }),
  episode: data => Episode({
    ...data,
    grouped_availability: _.map(data.grouped_availability, normalizeServiceGroups.default),
    ungrouped_availability: _.map(data.ungrouped_availability, normalizeService.default)
  })
}
