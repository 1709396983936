import { combineEpics } from 'redux-observable'
import _ from 'lodash-es'

import { BehaviorSubject } from 'rxjs'
import { from } from 'rxjs/observables'
import { isObservable } from 'rxjs/util'
import { mergeMap, catchError, filter } from 'rxjs/operators'

import { epics as epicsController } from '@reelgood/redux'
import 'glob:./!(index).js' // eslint-disable-line import/no-unresolved

const transformActions = actions => {
  if (_.isNil(actions) || _.isPlainObject(actions)) {
    return Promise.resolve(actions)
  }

  if (Array.isArray(actions)) {
    return from(actions)
  }

  if (isObservable(actions)) {
    return actions.pipe(
      mergeMap(transformActions)
    )
  }

  return actions
}

const outputTransformer = output$ => (
  output$.pipe(
    mergeMap(transformActions),
    filter(Boolean),
    catchError((err, source) => {
      console.log(err)

      return source
    })
  )
)

export default (action$, ...args) => {
  const epics = new BehaviorSubject(
    combineEpics(...epicsController.initial)
  )

  return outputTransformer(
    epics.pipe(
      mergeMap(epic => epic(action$, ...args))
    )
  )
}
