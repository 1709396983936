import movie from './movie'
import show from './show'
import episode from './episode'
import season from './season'

export default {
  movie,
  show,
  season,
  episode
}
