import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import classnames from 'classnames'

export default class ViewAll extends Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    servicesLeft: PropTypes.number
  }

  render() {
    const { servicesLeft } = this.props

    const {
      appearance: {
        general: { style },
        sub_text: { text_visible, color },
        services: {
          use_service_icons, text_color, stroke_color, round_service_icons, hover_animation, width: service_width,
          view_all: {
            display_remaining_sources, color: view_all_color, border_color: view_all_border_color,
            text, bold, capitalized, font_size: settingsFontSize, width: forced_width, height: forced_height
          }
        }
      },
      behavior: { services: { max_visible_services } }
    } = this.context.settings

    const shouldHavePadding = max_visible_services > 0

    const defaultWidth = 96
    const newFontSize = settingsFontSize || (Math.floor(12 * (service_width / defaultWidth)))
    const newBorderRadius = Math.floor(8 * (service_width / defaultWidth))
    const aspectRatio = defaultWidth / 46
    const viewAllServiceIcon = (
      <div
        className={styles.titleWrapper}
        style={{
          borderRadius: (round_service_icons && '6px') || null,
          borderColor: view_all_border_color,
          color: view_all_color,
          width: forced_width || service_width,
          height: forced_height || (service_width / aspectRatio)
        }}
      >
        <div
          className={classnames(styles.title, {
            [styles.bold]: bold,
            [styles.capitalized]: capitalized
          })}
          style={{
            fontSize: newFontSize
          }}
        >
          {text}
        </div>
      </div>
    )

    const viewAllTextIcon = (
      <span
        className={classnames(styles.subtitle, {
          [styles.button_only]: style === 'button_only',
          [styles.no_service_icons]: style !== 'button_only' && !use_service_icons,
          [styles.bold]: bold,
          [styles.capitalized]: capitalized
        })}
        style={{
          color: text_color,
          backgroundColor: stroke_color,
          width: (style === 'button_only' && '184px') || null,
          borderRadius: (!use_service_icons && round_service_icons && `${newBorderRadius}px`) || null,
          padding: (!use_service_icons && '0 8px 0') || null,
          fontSize: newFontSize
        }}
      >
        {text}
      </span>
    )

    const subtitle = servicesLeft > 0 &&
      use_service_icons && style !== 'text_only' &&
      display_remaining_sources &&
      text_visible &&
      (
        <span
          className={styles.subtitle}
          style={{
            color: color,
            fontSize: newFontSize
          }}
        >
          {`${servicesLeft} more`}
        </span>
      )

    return (
      <div
        role='button'
        tabIndex='0'
        onClick={this.props.toggleModal}
        className={classnames(styles.wrapper, styles[hover_animation], { [styles.padding]: shouldHavePadding })}
      >
        {use_service_icons && viewAllServiceIcon}

        {!use_service_icons && viewAllTextIcon}

        {subtitle}
      </div>
    )
  }
}
