import produce from 'immer'
import { constructStateId } from '~lib/state'
import { entityLoadSuccess, entityLoadFail, addEntities, entityUpdate, updateEntitys, loadEntity, entityLookupSuccess, lookupEntity } from '~actions/entities'
import _ from 'lodash-es'

const initialState = {
  entries: {},
  meta: {},
  foreign_ids: {}
}

export default function entities(state = initialState, { payload, type, meta }) {
  switch (type) {
    case loadEntity.toString(): {
      const typedId = constructStateId(payload.type, payload.id)

      return {
        ...state,
        meta: {
          ...state.meta,
          [typedId]: {
            ...state.meta[typedId],
            isLoading: true,
            loadState: _.get(state.meta[typedId], 'loadState', 'unloaded')
          }
        }
      }
    }
    case lookupEntity.toString(): {
      const typedId = constructStateId(payload.type, payload.id)

      return {
        ...state,
        meta: {
          ...state.meta,
          [typedId]: {
            ...state.meta[typedId],
            isLoading: true,
            loadState: _.get(state.meta[typedId], 'loadState', 'unloaded')
          }
        }
      }
    }
    case entityLoadSuccess.toString(): {
      return {
        ...state,
        meta: {
          ...state.meta,
          [payload.stateId]: produce(state.meta[payload.stateId], (meta = {}) => {
            meta.isLoading = false
            meta.loadState = payload.loadState || 'complete',
            meta.lastLoad = _.now()
          })
        }
      }
    }
    case entityLoadFail.toString(): {
      return {
        ...state,
        meta: {
          ...state.meta,
          [payload.stateId]: produce(state.meta[payload.stateId], (meta = {}) => {
            meta.isLoading = false
            meta.loadState = meta.loadState || 'unloaded'
          })
        }
      }
    }
    case addEntities.toString():
      return {
        ...state,
        entries: produce(state.entries, (entities) => {
          for (let entry in payload.entities) {
            if (!(entry in entities)) {
              entities[entry] = payload.entities[entry]
              continue
            }

            entities[entry] = _.mergeWith(entities[entry], payload.entities[entry], (objValue, srcValue, key) => {
              if (_.isEqual(objValue, srcValue)) {
                return objValue
              }

              if (_.isNil(objValue) || _.isObjectLike(objValue) && _.isEmpty(objValue)) {
                return srcValue
              }
            })
          }
        }),
        meta: {
          ...state.meta,
          ..._.mapValues(payload.entities, (v, key) => ({
            loadState: 'partial',
            ...state.meta[key],
            ...payload.entities_meta[key]
          }))
        }
      }
    case updateEntitys.toString():
      return {
        ...state,
        entries: produce(state.entries, entities => {
          _.merge(entities, payload.entities)
        }),
        meta: !payload.meta ? state.meta : produce(state.meta, meta => {
          _.merge(meta, payload.meta)
        })
      }
    case entityUpdate.toString():
      return {
        ...state,
        entries: {
          ...state.entries,
          [payload.id]: {
            ...state.entries[payload.id],
            ...payload.item
          }
        }
      }
    case entityLookupSuccess.toString():
      const id = `${payload.type}_${payload.id_type}_${payload.id}`
      return {
        ...state,
        foreign_ids: {
          ...state.foreign_ids,
          [id]: payload.rg_id
        }
      }
    default:
      return state
  }
}
