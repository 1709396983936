import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import classnames from 'classnames'
import _ from 'lodash-es'

export default function MissingIcon({ title, className, small }) {
  const {
    appearance: {
      services: {
        round_service_icons, missing_icon_bg_color, missing_icon_text_color,
      }
    }
  } = this.context.settings

  const getSplitLinesTitle = (title) => {
    const maxLineLength = small ? 10 : 9
    const splitTitle = title.split(' ')

    if (splitTitle.length === 1 && splitTitle[0].length <= maxLineLength) {
      return title
    }

    let firstLine = splitTitle[0].substring(0, maxLineLength)
    let secondLine = [splitTitle[0].substring(maxLineLength)]

    _.forEach(splitTitle.slice(1), (substring) => {
      if (firstLine.length + 1 + substring.length >= maxLineLength) {
        secondLine += substring
      } else {
        firstLine += ' ' + substring
      }
    })

    return [
      <div>{firstLine}</div>,
      <div className={styles.break}>{secondLine}</div>
    ]
  }

  return (
    <div
      className={classnames(styles.wrapper, className)}
      style={{
        borderRadius: (round_service_icons && '6px') || null,
        backgroundColor: missing_icon_bg_color,
        color: missing_icon_text_color
      }}
    >
      <div className={classnames(
        styles.title, 
        { 
          [styles.small]: small 
        })}
      >
        {getSplitLinesTitle(title)}
      </div>
    </div>
  )
}
