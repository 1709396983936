import _ from 'lodash-es'
import * as records from '~records'
import { createSelector } from 'reselect'

const argumentsPassThrough = (state, ...args) => args

const entriesStateSelector = state => state.entities.entries

const entriesMetaStateSelector = state => state.entities.meta

export const entityByStateId = createSelector(
  entriesStateSelector,
  argumentsPassThrough,
  (entities, [stateId]) => {
    const entity = entities[stateId]

    return [
      stateId,
      !entity ? void 0 : {
        ..._.get(records, [_.capitalize(entity.type), 'defaults']),
        ...entity
      }
    ]
  }
)

export const entityMetaByStateId = createSelector(
  entriesMetaStateSelector,
  argumentsPassThrough,
  (entities, [stateId]) => {
    const entity = entities[stateId]

    return [
      stateId,
      !entity ? void 0 : {
        ..._.get(records, [_.capitalize(entity.type), 'defaults']),
        ...entity
      }
    ]
  }
)

const foreignIdsStateSelector = state => state.entities.foreign_ids

export const rgIdForForeignId = createSelector(
  foreignIdsStateSelector,
  argumentsPassThrough,
  (foreign_ids, [content_type, id_type, id]) => {
    return foreign_ids[`${content_type}_${id_type}_${id}`] || void 0
  }
)
