import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import Dropdown from './dropdown'
import classnames from 'classnames'

export default class DropdownSelection extends Component {
  static propTypes = {
    title: PropTypes.string,
    itemSelected: PropTypes.func,
    items: PropTypes.array,
    disabled: PropTypes.bool,
    selectedItem: PropTypes.number,
    dropdownToggled: PropTypes.func
  }

  itemSelected = (index) => {
    this.props.itemSelected(index)
    this.toggleDropdown()
  }

  state = {
    dropdownIsOpen: false
  }

  closeDropdown = (e) => {
    if (!this.state.dropdownIsOpen || this.props.disabled) return

    this.props.dropdownToggled(false, this.props.title)
    this.setState({ dropdownIsOpen: false })

    e.stopPropagation()
  }

  toggleDropdown = () => {
    if (this.props.disabled) return

    this.props.dropdownToggled(!this.state.dropdownIsOpen, this.props.title)
    this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })
  }

  render() {
    if (!this.props.items) {
      return null
    }

    const {
      appearance: {
        general: { theme },
        popup: {
          dropdown: { text_color, item_title_text_color, bg_color, open_bg_color, border_color, arrow_icon_color }
        }
      }
    } = this.context.settings

    const { title, items, selectedItem } = this.props

    return (
      <div
        className={classnames(styles.wrapper, { [styles.disabled]: this.props.disabled && !this.state.dropdownIsOpen })}
        role='button'
        tabIndex='0'
        onClick={this.toggleDropdown}
        style={{
          backgroundColor: bg_color
        }}
      >
        <div
          className={classnames(styles.button, styles[theme])}
          style={{
            borderColor: border_color
          }}
        >
          <div
            className={styles.title}
            style={{
              color: text_color
            }}
          >
            {title}
          </div>
          <div
            className={classnames(styles.selectedItem, styles[theme])}
            style={{
              color: this.state.dropdownIsOpen ? item_title_text_color : text_color,
              borderLeftColor: border_color
            }}
          >
            {items[selectedItem] ? items[selectedItem].selected : 'ALL'}
            <svg width="19px" height="10px" viewBox="0 0 19 10" stroke={arrow_icon_color} stroke-width="3" fill="none">
              {this.state.dropdownIsOpen
                ? (
                  <g id="Artboard" transform="translate(-272.000000, -162.000000)" >
                    <g id="rightArrowGrey" transform="translate(281.500000, 170.500000) rotate(-90.000000) translate(-281.500000, -170.500000) translate(273.000000, 162.000000)">
                      <polyline id="Path-15" transform="translate(8.485281, 8.485281) rotate(-45.000000) translate(-8.485281, -8.485281) " points="2.485281 14.485281 13.315495 13.315495 14.485281 2.485281" />
                    </g>
                  </g>
                ) : (
                  <g id="Artboard" transform="translate(-255.000000, -152.000000)">
                    <g id="rightArrowWhite" transform="translate(264.500000, 153.500000) rotate(90.000000) translate(-264.500000, -153.500000) translate(256.000000, 145.000000)">
                      <polyline id="Path-15-Copy-11" transform="translate(8.485281, 8.485281) rotate(-45.000000) translate(-8.485281, -8.485281) " points="2.485281 14.485281 13.315495 13.315495 14.485281 2.485281" />
                    </g>
                  </g>
                )
              }
            </svg>
          </div>
        </div>
        { this.state.dropdownIsOpen && (
          <Dropdown items={items} itemSelected={this.itemSelected} closeDropdown={this.closeDropdown} />
        )}
      </div>
    )
  }
}
