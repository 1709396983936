import { createAction } from 'redux-actions'

export const SHOW_OPEN = 'SHOW_OPEN'
export const SHOW_OPEN_SUCCESS = 'SHOW_OPEN_SUCCESS'
export const SHOW_OPEN_FAIL = 'SHOW_OPEN_FAIL'
export const SEASON_LOAD = 'SEASON_LOAD'
export const SEASON_LOAD_SUCCESS = 'SEASON_LOAD_SUCCESS'
export const SEASON_LOAD_FAIL = 'SEASON_LOAD_FAIL'

export const openShow = createAction(
  SHOW_OPEN,
  (type, id) => ({ type, id })
)

export const showOpenSuccess = createAction(
  SHOW_OPEN_SUCCESS,
  (type, id) => ({ type, id })
)

export const showOpenFail = createAction(
  SHOW_OPEN_FAIL,
  (type, id) => ({ type, id })
)
