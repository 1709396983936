import PropTypes from 'prop-types'
import styles from './styles.scss'
import classnames from 'classnames'
import MissingIcon from '~components/services/missingIcon'

export default function RowService({ id, display_name, logo_slug, quantity, quantityLabel, pricingLabel, buyLabel }) {
  const {
    appearance: {
      general: { theme },
      services: { round_service_icons },
      popup: {
        services: { title_color, subtitle_color, arrow_icon_color, border_color, border_weight, border_style }
      }
    },
    behavior: {
      services: { group_services }
    }
  } = this.context.settings

  return (
    <div 
      className={classnames(styles.service_wrapper, styles[theme])} 
      onClick={this.props.itemSelected} 
      role='button' 
      tabIndex='0'
    >
      <div className={styles.labels}>
        {logo_slug 
          ? <img
            src={`https://img.reelgood.com/service-logos/${logo_slug}@x1.png`}
            className={styles.service_icon}
            alt={id}
            key={id}
            style={{
              borderRadius: (round_service_icons && '6px') || null,
              borderColor: (border_weight && border_color) || null,
              borderWidth: (border_weight && `${border_weight}px`) || null,
              borderStyle: (border_weight && border_style) || null
            }}
          />
          : <MissingIcon className={styles.service_icon} title={display_name} small />
        }

        <div className={styles.titles}>
          <span
            className={styles.service_title}
            style={{
              color: title_color
            }}
          >
            {display_name}
          </span>


          <div className={styles.subtitles}>
            {!!quantity && quantityLabel && (
              <span
                className={styles.quantity}
                style={{
                  color: subtitle_color
                }}
              >
                {quantity} {quantityLabel}{quantity > 1 ? 's' : void 0}
              </span>
            )}

            {pricingLabel && (
              <span
                style={{
                  color: subtitle_color
                }}
              >
                {pricingLabel}
              </span>
            )}

            {buyLabel && (
              <span
                style={{
                  color: subtitle_color
                }}
              >
                {buyLabel}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={styles.arrow}>
        <svg viewBox="6 -3.5 10 19" id="On-page-widget" stroke={arrow_icon_color} stroke-width="2" fill="none" fill-rule="evenodd">
          <polyline id="Path-15-Copy-11" transform="rotate(-45.000000)" points="-5.485281 15.485281 6.315495 14.315495 7.485281 3.485281" />
        </svg>
      </div>
    </div>
  )
}

RowService.propTypes = {
  id: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  quantityLabel: PropTypes.string,
  pricingLabel: PropTypes.string,
  buyLabel: PropTypes.string,
  itemSelected: PropTypes.func
}
