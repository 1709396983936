import reducers from './reducers'
import { createStore } from '@reelgood/redux'
import epics from '~epics/index'
import facets from '~facets'
import transports from '@reelgood/js-sdk/es/transports'
import { saveState, loadState } from './stateloader'
import _ from 'lodash-es'
import ReelgoodSDK from '@reelgood/js-sdk/es/sdk'

class ReelgoodSDKPub extends ReelgoodSDK {
  constructor(userDefinedAPIKey, prod = true) {
    super(
      {},
      {
        transport: {
          options: {
            debug: true
          }
        }
      }
    )

    const transport = transports[this._options.transport.name]({
      ...this._options.transport.options,
      platform: this._options.platform
    })

    for (let key in facets) {
      this[`js_${key}`] = new facets[key](userDefinedAPIKey, prod, transport, this._options, this._auth, this)
    }
  }
}

const rgAPIKeyFromElement = (element) => {
  const queryString = element.src.split('?')[1]
  if (!queryString) return undefined

  const rgAPIKeyPatt = /(rgAPIKey=)\w+/g
  const match = rgAPIKeyPatt.exec(queryString)
  if (match && match[0]) {
    return match[0].split('=')[1]
  }

  return undefined
}

export default function () {
  const bundleImport = document.getElementById('rg-js-widget-bundle') || document.getElementById('reelgood-js-widget-bundle') || document.querySelector('[data-rg-js-widget]') || document.querySelector('[data-reelgood-js-widget]')

  let userDefinedAPIKey = _.get(bundleImport, 'dataset.apiKey')
  const userDefinedProd = (_.get(bundleImport, 'dataset.env') || 'production') === 'production'

  if (!userDefinedAPIKey) {
    console.warn('No API Key found in the script tag dataset. Attempting to retrieve it from the bundle.js url.')

    if (bundleImport) {
      console.log('Bundle import found by ID.')

      const rgAPIKey = rgAPIKeyFromElement(bundleImport)
      console.log(`RG API Key found in bundle.js url.\nFull url: ${bundleImport.src}\nAPI Key: ${rgAPIKey}`)

      userDefinedAPIKey = rgAPIKey
    } else {
      console.warn('No bundle import found by ID. Mathing all bundle.js imports.')

      _.forEach(document.querySelectorAll('script[src*=\'bundle.js\']'), (element) => {
        const rgAPIKey = rgAPIKeyFromElement(element)

        if (rgAPIKey) {
          console.log(`RG API Key found in bundle.js url.\nFull url: ${element.src}\nAPI Key: ${rgAPIKey}`)
          userDefinedAPIKey = rgAPIKey
        }
      })

      if (!userDefinedAPIKey) {
        _.forEach(document.querySelectorAll('script[src*=\'bundle-local.js\']'), (element) => {
          const rgAPIKey = rgAPIKeyFromElement(element)

          if (rgAPIKey) {
            console.log(`RG API Key found in bundle-local.js url.\nFull url: ${element.src}\nAPI Key: ${rgAPIKey}`)
            userDefinedAPIKey = rgAPIKey
          }
        })
      }
    }
  }

  const sdk = new ReelgoodSDKPub(userDefinedAPIKey, userDefinedProd)

  const store$ = createStore(
    {
      epics,
      reducers,
      middleware: [],
      SSR: false,
      epicsDependencies: {
        sdk
      }
    },
    loadState()
  )

  store$.subscribe(() => {
    saveState(store$.getState())
  })

  return store$
}
