import PropTypes from 'prop-types'
import styles from './styles.scss'

export default function seperator() {
  return (
    <div className={styles.seperator} style={{
      marginRight: this.props.short ? '60px' : '0px',
      backgroundColor: this.context.settings.appearance.popup.general.border_color
    }} />
  )
}

seperator.propTypes = {
  short: PropTypes.bool
}
