import { Component } from 'preact'
import { connect } from 'preact-redux'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import { entityByStateId, entityMetaByStateId } from '~selectors/entities'
import classnames from 'classnames'

import InlineServices from '~components/services/inlineServices'

const mapStateToProps = (state, { state_id, id_type }) => {
  if (!state_id) {
    return {
      isLoading: id_type !== 'rg'
    }
  }

  const entity = entityByStateId(state, state_id)[1]
  const entityMeta = entityMetaByStateId(state, state_id)[1]

  return {
    state_id,
    ...entity,
    ...entityMeta
  }
}

@connect(mapStateToProps)
export default class Widget extends Component {
  static propTypes = {
    itemSelected: PropTypes.func,
    toggleModal: PropTypes.func,
    trackEvent: PropTypes.func,
    id_type: PropTypes.string
  }

  render() {
    const {
      title, type, slug, grouped_availability, ungrouped_availability,
      isLoading, loadState, toggleModal, itemSelected
    } = this.props
    const rgUrl = `https://www.reelgood.com/${(type && slug && `${type}/${slug}`) || ''}`

    const {
      appearance: {
        general: { style, drop_shadow_visible, bg_color, border_color, border_weight, border_style, empty_state_color, widget_padding },
        header: { text_visible, color, stream_color, break_title, capitalized },
        powered_by: { vertical, horizontal, hidden: poweredByHidden }
      },
      behavior: {
        services: {
          group_services
        }
      }
    } = this.context.settings

    const services = (group_services ? grouped_availability : ungrouped_availability) || []
    const maxWidth = services && (services.length > 0 && `${(110 * (services.length + 1)) + 48}px`)

    const poweredByMarginTop = vertical === 'bottom'
      ? style === 'button_only'
        ? '8px'
        : '16px'
      : horizontal === 'left'
        ? '8px'
        : null

    const poweredBy = !poweredByHidden && (
      <div
        className={classnames(
          styles.powered_by,
          {
            [styles.flexgrow]: horizontal === 'right'
          }
        )}
        style={{
          width: (vertical === 'bottom' && '100%') || null,
          textAlign: horizontal,
          marginTop: poweredByMarginTop
        }}
      >
        <a target='_blank' href={rgUrl}>Powered by Reelgood</a>
      </div>
    )

    const headerTitle = (
      text_visible && title && (
        <div
          className={classnames(
            styles.title,
            {
              [styles.breakTitle]: break_title,
              [styles.capitalized]: capitalized
            }
          )}
          style={{
            color: color
          }}
        >
          <span style={{color: stream_color}}>Where to Stream</span>
          <span className={styles.contentTitle}>{title}</span>
        </div>
      )
    )

    const emptyState = (!services.length && !title && !isLoading)
      ? (
        <span style={{color: empty_state_color}}>
          Where to Watch Widget Failed To Load. Please Try Again.
        </span>
      )
      : (!services.length && !isLoading && style !== 'button_only')
        ? (
          <div style={{color: empty_state_color, marginTop: '16px'}}>
            No Rent, Buy, Subscribe, or Free Streaming Services Found Get Notified When It's Available, <a target='_blank' href={rgUrl} style={{textDecoration: 'underline'}}>Watchlist it on Reelgood</a>
          </div>
        )
        : null

    return (
      <div
        className={classnames(
          styles.wrapper,
          styles[style],
          {
            [styles.drop_shadow]: drop_shadow_visible,
            [styles.padding]: widget_padding
          })}
        style={{
          backgroundColor: bg_color,
          borderColor: (border_weight && border_color) || null,
          borderWidth: (border_weight && `${border_weight}px`) || null,
          borderStyle: (border_weight && border_style) || null,
          maxWidth: maxWidth
        }}
      >
        <div className={styles.content}>
          {(headerTitle || vertical === 'top') &&
            <div className={styles.header}>
              {headerTitle}
              {vertical === 'top' && poweredBy}
            </div>}

          {emptyState}

          {(!emptyState && (!isLoading || loadState === 'complete') || style === 'button_only') && (
            <InlineServices
              services={services}
              toggleModal={toggleModal}
              itemSelected={itemSelected}
            />
          )}

          {vertical === 'bottom' && poweredBy}
        </div>
      </div>
    )
  }
}
