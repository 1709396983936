import CORSFacet from './corsFacet'
import { decorate, enumerable } from 'core-decorators'

export default class movie extends CORSFacet {
  @enumerable
  @decorate(CORSFacet.ensureParamsAndCallback)
  lookup(id, id_type, { auth, ...query } = {}) {
    return this.corsTransport('', { url: `${this.parterAPIUrl}/lookup/movie/`, query: { id, id_type, ...query } }, auth)
  }

  @enumerable
  @decorate(CORSFacet.ensureParamsAndCallback)
  details(id, { auth, ...query } = {}) {
    return this.corsTransport('', { url: `${this.parterAPIUrl}/movie/${id}`, ...query }, auth)
  }

  link(id, service_id) {
    return `${this.linkingUrl}/coupler?type=movie&content_id=${id}&service_id=${service_id}&api_key=${this.apiKey}`
  }
}
