import { createAction } from 'redux-actions'

export const SEASON_LOAD = 'SEASON_LOAD'
export const SEASON_LOAD_SUCCESS = 'SEASON_LOAD_SUCCESS'
export const SEASON_LOAD_FAIL = 'SEASON_LOAD_FAIL'

export const loadSeason = createAction(
  SEASON_LOAD,
  (type, id, season) => ({ type, id, season })
)

export const seasonLoadSuccess = createAction(
  SEASON_LOAD_SUCCESS,
  (type, id, season) => ({ type, id, season })
)

export const seasonLoadFail = createAction(
  SEASON_LOAD_FAIL,
  (type, id, season) => ({ type, id, season })
)
