import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import _ from 'lodash-es'
import classnames from 'classnames'
import InlineService from './inlineService'
import ViewAll from './viewAll'

export default class InlineServices extends Component {
  static propTypes = {
    max: PropTypes.number,
    services: PropTypes.array.isRequired,
    toggleModal: PropTypes.func,
    itemSelected: PropTypes.func,
    contentType: PropTypes.string,
    group_services: PropTypes.bool
  }

  accessTypeLabel = (service) => {
    if (service.access_type) {
      switch (service.access_type) {
        case 0: return 'Free w/ Ads'
        case 1: return 'TV Everywhere'
        case 2: return 'Subscription'
        case 3: return 'Rent or Buy'
        default: return 'hello'
      }
    } else if (service.access_types) {
      if (service.access_types.length > 1) {
        return 'Multiple'
      } else {
        switch (service.access_types[0]) {
          case 0: return 'Free w/ Ads'
          case 1: return 'TV Everywhere'
          case 2: return 'Subscription'
          case 3: return 'Rent or Buy'
          default: return 'world'
        }
      }
    }
  }

  render() {
    const { toggleModal, services } = this.props
    const {
      appearance: {
        general: { style },
        services: { use_service_icons, center }
      },
      behavior: {
        services: { group_services, max_visible_services, service_groups_prioritization, service_platforms_prioritization }
      }
    } = this.context.settings

    let displayServices = [...services]

    if (group_services && service_groups_prioritization && service_groups_prioritization.length) {
      displayServices = _.sortBy(displayServices, (service) => {
        const groupIndex = _.indexOf(service_groups_prioritization, group_services ? service.id : service.group_id)
        return (groupIndex === -1) ? Number.MAX_SAFE_INTEGER : groupIndex
      }) // eslint-disable-line array-func/prefer-array-from
    } else if (!group_services && service_platforms_prioritization && service_platforms_prioritization.length) {
      displayServices = _.sortBy(displayServices, (service) => {
        const platformIndex = _.indexOf(service_platforms_prioritization, service.id)
        return (platformIndex === -1) ? Number.MAX_SAFE_INTEGER : platformIndex
      }) // eslint-disable-line array-func/prefer-array-from
    }

    if (displayServices.length === 0 && style !== 'button_only') {
      return null
    }

    // View All button is always visible for now
    // const viewAllButton = (displayServices.length > max_visible_services || contentType === 'show') && (
    //   <ViewAll toggleModal={toggleModal} servicesLeft={displayServices.length - max_visible_services} />
    // )

    return (
      <div className={classnames(
        styles.services,
        {
          [styles.center]: center
        }
      )}>
        {_.take(displayServices, max_visible_services)
          .map((data, index) => {
            return (
              <InlineService
                id={data.id}
                logo_slug={data.logo_slug}
                access_type={this.accessTypeLabel(data)}
                service_title={data.name}
                imageHidden={!use_service_icons}
                itemSelected={() => this.props.itemSelected(data, index)}
              />
            )
          })}
        <ViewAll toggleModal={toggleModal} servicesLeft={displayServices.length - max_visible_services} />
      </div>
    )
  }
}
