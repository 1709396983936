import { createAction } from 'redux-actions'

export const ENTITYS_ADD = 'ENTITYS_ADD'
export const ENTITY_UPDATE = 'ENTITY_UPDATE'
export const ENTITYS_UPDATE = 'ENTITYS_UPDATE'

export const ENTITY_LOAD = 'ENTITY_LOAD'
export const ENTITY_LOAD_SUCCESS = 'ENTITY_LOAD_SUCCESS'
export const ENTITY_LOAD_FAIL = 'ENTITY_LOAD_FAIL'

export const ENTITY_LOOKUP = 'ENTITY_LOOKUP'
export const ENTITY_LOOKUP_SUCCESS = 'ENTITY_LOOKUP_SUCCESS'
export const ENTITY_LOOKUP_FAIL = 'ENTITY_LOOKUP_FAIL'

export const ENTITY_LINK = 'ENTITY_LINK'
export const ENTITY_LINK_SUCCESS = 'ENTITY_LINK_SUCCESS'
export const ENTITY_LINK_FAIL = 'ENTITY_LINK_FAIL'

export const addEntities = createAction(
  ENTITYS_ADD,
  (entities, entities_meta = {}) => ({ entities, entities_meta })
)

export const entityUpdate = createAction(
  ENTITY_UPDATE,
  (id, item) => ({ id, item })
)

export const loadEntity = createAction(
  ENTITY_LOAD,
  (type, id) => ({ type, id })
)

export const entityLoadSuccess = createAction(
  ENTITY_LOAD_SUCCESS,
  (type, id, stateId, rest) => ({ type, id, stateId, ...rest })
)

export const entityLoadFail = createAction(
  ENTITY_LOAD_FAIL,
  (type, id, stateId, rest) => ({ type, id, stateId, ...rest })
)

export const updateEntitys = createAction(
  ENTITYS_UPDATE,
  (entities, meta) => ({ entities, meta })
)

export const lookupEntity = createAction(
  ENTITY_LOOKUP,
  (type, id, id_type) => ({ type, id, id_type })
)

export const entityLookupSuccess = createAction(
  ENTITY_LOOKUP_SUCCESS,
  (type, id, id_type, rg_id) => ({ type, id, id_type, rg_id })
)

export const entityLookupFail = createAction(
  ENTITY_LOOKUP_FAIL,
  (type, id, id_type) => ({ type, id, id_type })
)

export const linkEntity = createAction(
  ENTITY_LINK,
  (type, id, service_id, tracking_properties) => ({ type, id, service_id, tracking_properties })
)

export const entityLinkSuccess = createAction(
  ENTITY_LINK_SUCCESS,
  (type, id, service_id) => ({ type, id, service_id })
)

export const entityLinkFail = createAction(
  ENTITY_LINK_FAIL,
  (type, id, service_id) => ({ type, id, service_id })
)
