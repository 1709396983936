/* eslint-disable no-dupe-keys */
import PropTypes from 'prop-types'
import _ from 'lodash-es'

export const settingsPropType = () => {
  return PropTypes.shape({
    appearance: PropTypes.shape({
      general: PropTypes.shape({
        style: PropTypes.string,
        theme: PropTypes.string,
        drop_shadow_visible: PropTypes.bool,
        bg_color: PropTypes.string,
        border_color: PropTypes.string,
        border_weight: PropTypes.number,
        border_style: PropTypes.string,
        empty_state_color: PropTypes.string,
        widget_padding: PropTypes.bool
      }),
      powered_by: PropTypes.shape({
        vertical: PropTypes.string,
        horizontal: PropTypes.string,
        hidden: PropTypes.bool
      }),
      header: PropTypes.shape({
        text_visible: PropTypes.bool,
        color: PropTypes.string,
        stream_color: PropTypes.string,
        break_title: PropTypes.bool,
        capitalized: PropTypes.bool
      }),
      sub_text: PropTypes.shape({
        text_visible: PropTypes.bool,
        color: PropTypes.string
      }),
      services: PropTypes.shape({
        round_service_icons: PropTypes.bool,
        use_service_icons: PropTypes.bool,
        stroke_color: PropTypes.string,
        text_color: PropTypes.string,
        platform_text_color: PropTypes.string,
        missing_icon_bg_color: PropTypes.string,
        missing_icon_text_color: PropTypes.string,
        border_color: PropTypes.string,
        border_weight: PropTypes.string,
        border_style: PropTypes.string,
        hover_animation: PropTypes.string,
        center: PropTypes.bool,
        width: PropTypes.number,
        view_all: PropTypes.shape({
          display_remaining_sources: PropTypes.bool,
          color: PropTypes.string,
          border_color: PropTypes.string,
          text: PropTypes.string.isRequired,
          bold: PropTypes.bool,
          capitalized: PropTypes.bool,
          font_size: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number
        })
      }),
      popup: PropTypes.shape({
        general: PropTypes.shape({
          bg_color: PropTypes.string,
          border_color: PropTypes.string,
          border_weight: PropTypes.number,
          border_style: PropTypes.string,
          close_icon_color: PropTypes.string
        }),
        header: PropTypes.shape({
          color: PropTypes.string
        }),
        dropdown: PropTypes.shape({
          text_color: PropTypes.string,
          item_title_text_color: PropTypes.string,
          item_subtitle_text_color: PropTypes.string,
          bg_color: PropTypes.string,
          open_bg_color: PropTypes.string,
          border_color: PropTypes.string,
          arrow_icon_color: PropTypes.bool
        }),
        access_types: PropTypes.shape({
          bg_color: PropTypes.string,
          // hover_bg_color: PropTypes.string,
          selected_bg_color: PropTypes.string,
          text_color: PropTypes.string,
          // hover_text_color: PropTypes.string,
          selected_text_color: PropTypes.string,
          border_color: PropTypes.string,
          // hover_border_color: PropTypes.string,
          selected_border_color: PropTypes.string
        }),
        services: {
          title_color: PropTypes.string,
          subtitle_color: PropTypes.string,
          platform_text_color: PropTypes.string,
          arrow_icon_color: PropTypes.bool,
          // hover_bg_color: PropTypes.string,
          border_color: PropTypes.string,
          border_weight: PropTypes.string,
          border_style: PropTypes.string
        }
      })
    }),
    behavior: PropTypes.shape({
      general: PropTypes.shape({
        cache_duration: PropTypes.number
      }),
      services: PropTypes.shape({
        group_services: PropTypes.bool,
        max_visible_services: PropTypes.bool,
        service_groups_prioritization: PropTypes.arrayOf(PropTypes.number),
        service_platforms_prioritization: PropTypes.arrayOf(PropTypes.number)
      })
    }),
    tracking: PropTypes.shape({
      google: PropTypes.shape({
        enabled: PropTypes.bool,
        event_category: PropTypes.string
      }),
      mixpanel: PropTypes.shape({
        enabled: PropTypes.bool
      })
    })
  })
}

export const defaultSettings = {
  appearance: {
    general: {
      style: 'standard', // Standard, Minimal, Text Only
      theme: 'light', // Light(default), Dark
      drop_shadow_visible: true, // On (true), Off (false)
      bg_color: '#ffffff',
      border_color: '#ffffff',
      border_weight: 0,
      border_style: 'solid',
      empty_state_color: '#4a4a4a',
      widget_padding: true
    },
    powered_by: {
      vertical: 'top', // top(default), bottom
      horizontal: 'left', // left(default), right
      hidden: false // Only to be used if the publisher adds their own Powered By link to the page right next to the widget
    },
    header: {
      text_visible: true, // On (true), Off (false)
      color: '#000000',
      stream_color: undefined,
      break_title: false,
      capitalized: false
    },
    sub_text: {
      text_visible: true, // On (true), Off (false)
      color: '#787878'
    },
    services: {
      round_service_icons: true, // true, false
      use_service_icons: true,
      stroke_color: '#f5a623',
      text_color: '#ffffff',
      platform_text_color: '#081118',
      missing_icon_bg_color: '#516b76',
      missing_icon_text_color: '#ffffff',
      border_color: '#000000',
      border_weight: 0,
      border_style: 'solid',
      hover_animation: 'none',
      center: false,
      width: 96,
      view_all: {
        display_remaining_sources: false,
        color: '#979797',
        border_color: '#979797',
        text: 'More Options',
        bold: false,
        capitalized: true,
        font_size: undefined,
        width: undefined,
        height: undefined
      }
    },
    popup: {
      general: {
        bg_color: '#ffffff',
        border_color: '#d2d2d2',
        border_weight: 1,
        border_style: 'solid',
        close_icon_color: '#081118',
        empty_state_color: '#4a4a4a'
      },
      header: {
        color: '#000000'
      },
      dropdown: {
        text_color: '#081118',
        item_title_text_color: '#081118',
        item_subtitle_text_color: '#081118',
        bg_color: '#ffffff',
        open_bg_color: '#d8d8d8',
        border_color: '#d2d2d2',
        arrow_icon_color: '#081118'
      },
      access_types: {
        bg_color: '#ffffff',
        // hover_bg_color: 'rgba(0, 189, 118, 0.09)',
        selected_bg_color: '#00bd76',
        text_color: '#4a4a4a',
        // hover_text_color: '#00bd76',
        selected_text_color: '#ffffff',
        border_color: '#d8d8d8',
        // hover_border_color: '#00bd76',
        selected_border_color: '#00bd76'
      },
      services: {
        title_color: '#4a4a4a',
        subtitle_color: '#4a4a4a',
        platform_text_color: '#081118',
        arrow_icon_color: '#081118',
        // hover_bg_color: '#e8e8e8',
        border_color: '#000000',
        border_weight: 0,
        border_style: 'solid'
      }
    }
  },
  behavior: {
    general: {
      cache_duration: 1000 * 60 * 60 * 6 // 1000 miliseconds * 60 secs * 60 minutes * 6 hours
    },
    services: {
      group_services: true, // true, false
      max_visible_services: 4, // 0-?
      service_groups_prioritization: [], // list of service groups ids
      service_platforms_prioritization: [] // list of service platforms ids
    }
  },
  tracking: {
    google: {
      enabled: false,
      event_category: 'reelgood_pub_widget'
    },
    mixpanel: {
      enabled: false
    }
  }
}

const validatePropInList = (settings, path, list) => {
  const setValue = _.get(settings, path)
  if (list.includes(setValue)) return
  const defaultValue = _.get(defaultSettings, path)
  console.warn(`Settings property failed validation: '${setValue}' at path '${path}'.\nError type: Value not in list.\nSupported values: ${list}.\nUsing default value: ${defaultValue}`)
  _.set(settings, path, defaultValue)
}

const validatePropHigherThan = (settings, path, min) => {
  const setValue = _.get(settings, path)
  if (setValue >= min) return
  const defaultValue = _.get(defaultSettings, path)
  console.warn(`Settings property failed validation: '${setValue}' at path '${path}'.\nError type: Value lower than minimum.\nMinimum value: ${min}.\nUsing default value: ${defaultValue}`)
  _.set(settings, path, defaultValue)
}

const overridePropWhenStyle = (settings, path, styles, override) => {
  const setStyle = _.get(settings, 'appearance.general.style')
  if (!styles.includes(setStyle)) return
  // const setValue = _.get(settings, path)
  // const defaultValue = _.get(defaultSettingsForTheme(_.get(settings, 'appearance.general.theme')), path)
  // if (setValue !== defaultValue) console.warn(`Settings property failed validation: '${setValue}' at path '${path}' with style '${setStyle}'.\nError type: Value not allowed for set style.\nUnsupported by styles: ${styles}.\nOverriding with value: ${override}`)
  _.set(settings, path, override)
}

const overridePropWhenNotStyle = (settings, path, styles, override) => {
  const setStyle = _.get(settings, 'appearance.general.style')
  if (styles.includes(setStyle)) return
  // const setValue = _.get(settings, path)
  // const defaultValue = _.get(defaultSettingsForTheme(_.get(settings, 'appearance.general.theme')), path)
  // if (setValue !== defaultValue) console.warn(`Settings property failed validation: '${setValue}' at path '${path}' with style '${setStyle}'.\nError type: Value not allowed for set style.\nSupported by styles: ${styles}.\nOverriding with value: ${override}`)
  _.set(settings, path, override)
}

const overridePropWhenTheme = (settings, path, themes, override) => {
  const setTheme = _.get(settings, 'appearance.general.theme')
  if (!themes.includes(setTheme)) return
  // const setValue = _.get(settings, path)
  // const defaultValue = _.get(defaultSettingsForTheme(_.get(settings, 'appearance.general.theme')), path)
  // if (setValue !== defaultValue) console.warn(`Settings property failed validation: '${setValue}' at path '${path}' with style '${setTheme}'.\nError type: Value not allowed for set style.\nUnsupported by styles: ${themes}.\nOverriding with value: ${override}`)
  _.set(settings, path, override)
}

const overridePropWhenSettingEquals = (settings, setting, value, path, override) => {
  const setValue = _.get(settings, setting)
  if (setValue !== value) return
  const oldValue = _.get(settings, path)
  const defaultValue = _.get(defaultSettings, path)
  if (oldValue !== defaultValue) console.warn(`Settings property overriden: '${oldValue}' at path '${path}'.\nError type: Value '${setValue}' at path '${setting}' equals '${value}'.\nUsing override value: ${override}`)
  _.set(settings, path, override)
}

export const validateSettings = (settings) => {
  const validators = [
    () => { validatePropInList(settings, 'appearance.general.style', ['standard', 'minimal', 'text_only', 'button_only']) },
    () => { validatePropInList(settings, 'appearance.general.theme', ['light', 'dark']) },
    () => { validatePropHigherThan(settings, 'appearance.general.border_weight', 0) },
    () => { validatePropInList(settings, 'appearance.powered_by.vertical', ['top', 'bottom']) },
    () => { validatePropInList(settings, 'appearance.powered_by.horizontal', ['left', 'center']) },
    () => { validatePropInList(settings, 'appearance.services.hover_animation', ['bounce', 'fade', 'scale', 'none']) },
    () => { validatePropHigherThan(settings, 'behavior.general.cache_duration', 0) },
    () => { validatePropHigherThan(settings, 'behavior.services.max_visible_services', 0) }
  ]

  _.forEach(validators, (validator) => { validator() })

  return settings
}

export const overrideSettignsForOtherSettings = (settings) => {
  const validators = [
    () => overridePropWhenSettingEquals(settings, 'appearance.services.use_service_icons', false, 'appearance.services.view_all.capitalized', false),
    () => overridePropWhenSettingEquals(settings, 'appearance.services.use_service_icons', false, 'appearance.services.width', 96),
    () => overridePropWhenSettingEquals(settings, 'appearance.services.use_service_icons', true, 'appearance.services.stroke_color', false)
  ]

  _.forEach(validators, (validator) => { validator() })

  return settings
}

export const overrideSettingsForSetStyle = (settings) => {
  const validators = [
    () => { overridePropWhenNotStyle(settings, 'appearance.general.bg_color', ['standard', 'minimal'], false) },
    () => { overridePropWhenStyle(settings, 'appearance.general.widget_padding', ['text_only', 'button_only'], false) },
    () => { overridePropWhenStyle(settings, 'appearance.sub_text.text_visible', ['text_only', 'button_only'], true) },
    () => { overridePropWhenStyle(settings, 'appearance.services.use_service_icons', ['text_only', 'button_only'], false) },
    () => { overridePropWhenStyle(settings, 'appearance.powered_by.vertical', ['button_only'], 'bottom') },
    () => { overridePropWhenStyle(settings, 'appearance.powered_by.horizontal', ['button_only'], 'center') },
    () => { overridePropWhenNotStyle(settings, 'appearance.powered_by.horizontal', ['button_only'], 'left') },
    () => { overridePropWhenStyle(settings, 'behavior.services.max_visible_services', ['button_only'], 0) },
    () => { overridePropWhenStyle(settings, 'appearance.services.text_color', ['button_only'], '#ffffff') },
    () => { overridePropWhenStyle(settings, 'appearance.services.center', ['button_only'], true) }
  ]

  _.forEach(validators, (validator) => { validator() })

  return settings
}

export const defaultSettingsForSetStyle = (settings) => {
  const validators = [
    () => { overridePropWhenStyle(settings, 'appearance.general.widget_padding', ['standard', 'minimal'], true) },
    () => { overridePropWhenNotStyle(settings, 'appearance.general.border_color', ['standard'], false) },
    () => { overridePropWhenNotStyle(settings, 'appearance.general.border_weight', ['standard'], false) },
    () => { overridePropWhenNotStyle(settings, 'appearance.general.border_style', ['standard'], false) },
    () => { overridePropWhenNotStyle(settings, 'appearance.general.drop_shadow_visible', ['standard'], false) },
    () => { overridePropWhenNotStyle(settings, 'appearance.header.text_visible', ['standard'], false) },
    () => { overridePropWhenStyle(settings, 'appearance.sub_text.text_visible', ['minimal'], false) },
    // () => { overridePropWhenNotStyle(settings, 'appearance.services.stroke_color', ['text_only', 'button_only'], false) },
    () => { overridePropWhenStyle(settings, 'appearance.services.use_service_icons', ['minimal'], true) },
    () => { overridePropWhenStyle(settings, 'appearance.services.view_all.text', ['button_only'], 'Where to Watch') }
  ]

  _.forEach(validators, (validator) => { validator() })

  return settings
}

export const defaultSettingsForSetTheme = (settings) => {
  const validators = [
    () => { overridePropWhenTheme(settings, 'appearance.general.bg_color', ['dark'], '#081118') },
    () => { overridePropWhenTheme(settings, 'appearance.general.border_color', ['dark'], '#787878') },
    () => { overridePropWhenTheme(settings, 'appearance.general.empty_state_color', ['dark'], '#ffffff') },

    () => { overridePropWhenTheme(settings, 'appearance.header.color', ['dark'], '#ffffff') },

    () => { overridePropWhenTheme(settings, 'appearance.services.border_color', ['dark'], '#d8d8d8') },
    () => { overridePropWhenTheme(settings, 'appearance.services.border_weight', ['dark'], 1) },
    () => { overridePropWhenTheme(settings, 'appearance.services.border_style', ['dark'], 'solid') },
    () => { overridePropWhenTheme(settings, 'appearance.services.platform_text_color', ['dark'], '#ffffff') },

    () => { overridePropWhenTheme(settings, 'appearance.popup.general.bg_color', ['dark'], '#081118') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.general.border_color', ['dark'], '#787878') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.general.close_icon_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.general.empty_state_color', ['dark'], '#ffffff') },

    () => { overridePropWhenTheme(settings, 'appearance.popup.header.color', ['dark'], '#ffffff') },

    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.text_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.item_title_text_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.item_subtitle_text_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.bg_color', ['dark'], '#081118') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.open_bg_color', ['dark'], '#4a4a4a') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.border_color', ['dark'], '#787878') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.dropdown.arrow_icon_color', ['dark'], '#ffffff') },

    () => { overridePropWhenTheme(settings, 'appearance.popup.services.title_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.services.subtitle_color', ['dark'], '#9b9b9b') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.services.platform_text_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.services.arrow_icon_color', ['dark'], '#ffffff') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.services.border_color', ['dark'], '#d8d8d8') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.services.border_weight', ['dark'], 1) },
    () => { overridePropWhenTheme(settings, 'appearance.popup.services.border_style', ['dark'], 'solid') },

    () => { overridePropWhenTheme(settings, 'appearance.popup.access_types.bg_color', ['dark'], '#081118') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.access_types.text_color', ['dark'], '#d8d8d8') },
    () => { overridePropWhenTheme(settings, 'appearance.popup.access_types.text_color', ['dark'], '#d8d8d8') }
  ]

  _.forEach(validators, (validator) => { validator() })

  return settings
}

export const mergeDefaultSettings = (settings) => {
  let modifiedDefaultSettings = _.cloneDeep(defaultSettings)

  const setTheme = _.get(settings, 'appearance.general.theme')
  if (setTheme) {
    _.set(modifiedDefaultSettings, 'appearance.general.theme', setTheme)
    modifiedDefaultSettings = defaultSettingsForSetTheme(modifiedDefaultSettings)
  }

  const setStyle = _.get(settings, 'appearance.general.style')
  if (setStyle) {
    _.set(modifiedDefaultSettings, 'appearance.general.style', setStyle)
    modifiedDefaultSettings = defaultSettingsForSetStyle(modifiedDefaultSettings)
  }

  return _.merge({}, modifiedDefaultSettings, settings)
}

export const normalizeSettings = (settings) => {
  return (
    overrideSettignsForOtherSettings(
      validateSettings(
        overrideSettingsForSetStyle(
          mergeDefaultSettings(settings)
        )
      )
    )
  )
}
