import { Component } from 'preact'
import { connect } from 'preact-redux'
import PropTypes from 'prop-types'
import { loadEntity, lookupEntity, linkEntity } from '~actions/entities'
import { openShow } from '~actions/show'
import { entityByStateId, entityMetaByStateId, rgIdForForeignId } from '~selectors/entities'
import { constructStateId } from '~lib/state'
import { settingsPropType } from '~settings'
import _ from 'lodash-es'
import Loadable from 'preact-loadable'
import Widget from '~components/widget'
import Popup from '~components/popup'

// const Popup = props => (
//   <Loadable
//     // Can be sync or async
//     fn={() => import('~components/popup').then(m => m.default)}
//     error={err => "Oops, an error occurred: " + err.message}
//     loading={null}
//     success={Result => <Result {...props} />}
//   />
// )

// const Widget = props => (
//   <Loadable
//     // Can be sync or async
//     fn={() => import('~components/widget').then(m => m.default)}
//     error={err => "Oops, an error occurred: " + err.message}
//     loading={null}
//     success={Result => <Result {...props} />}
//   />
// )

const mapDispatchToProps = (dispatch, props) => ({
  loadRGData: (id, idType, contentType) => {
    if (idType === 'rg') {
      return dispatch(loadEntity(contentType, id))
    }
    return dispatch(lookupEntity(contentType, id, idType))
  },
  loadRGPopupData: (contentType, id) => {
    return dispatch(openShow(contentType, id))
  },
  openServiceLink: (serviceId, contentType, id, tracking_properties) => {
    return dispatch(linkEntity(contentType, id, serviceId, tracking_properties))
  }
})

const mapStateToProps = (state, { content_type, id, id_type }) => {
  const _id = id_type !== 'rg' ? rgIdForForeignId(state, content_type, id_type, id) : id

  if (!_id) {
    return {}
  }

  const state_id = constructStateId(content_type, _id)
  const entity = entityByStateId(state, state_id)[1]
  const entityMeta = entityMetaByStateId(state, state_id)[1]

  return {
    state_id,
    id: _id,
    id_type: 'rg',
    ...entity,
    ...entityMeta
  }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class App extends Component {
  static propTypes = {
    // User defined
    content_type: PropTypes.string,
    id: PropTypes.string,
    id_type: PropTypes.string,
    state_id: PropTypes.string,

    // Settings
    settings: settingsPropType(),

    // Loaded entity data
    title: PropTypes.string,
    slug: PropTypes.string,
    grouped_availability: PropTypes.array,
    ungrouped_availability: PropTypes.array,

    // Functions
    loadRGData: PropTypes.func,
    loadRGPopupData: PropTypes.func,
    openServiceLink: PropTypes.func
  }

  static defaultProps = {
    grouped_availability: [],
    ungrouped_availability: []
  }

  static contextTypes = {
    settings: settingsPropType()
  }

  state = {
    popupOpen: false,
    preselectedServiceGroupId: undefined,
    preselectedServiceGroupName: undefined
  }

  componentWillMount() {
    const {
      settings: {
        behavior: {
          general: { cache_duration }
        }
      },
      id, id_type, content_type, title, lastLoad, loadRGData
    } = this.props

    // begin loading data we need
    if (
      id_type === 'rg' &&
      lastLoad &&
      (_.now() - lastLoad < cache_duration)
    ) {
      return
    }

    loadRGData(id, id_type, content_type)
  }

  getChildContext() {
    return {
      settings: this.props.settings
    }
  }

  trackEvent = (title, properties) => {
    let eventTitle = title.replace(/ /g, '_')
    eventTitle = eventTitle.replace(/\(/g, '')
    eventTitle = eventTitle.replace(/\)/g, '')

    if (this.props.settings.tracking.google.enabled) {
      const eventCategory = this.props.settings.tracking.google.event_category
      const eventLabel = JSON.stringify(properties) || `${this.props.title} - ${this.props.year} - ${this.props.content_type.charAt(0).toUpperCase() + this.props.content_type.slice(1)}`

      if (_.isFunction(window.gtag)) {
        window.gtag('event', eventTitle, {
          event_category: eventCategory,
          event_label: eventLabel
        })
      } else if (_.isFunction(window.ga)) {
        window.ga('send', {
          hitType: 'event',
          eventAction: eventTitle,
          eventCategory: eventCategory,
          eventLabel: eventLabel
        })
      }
    }

    if (this.props.settings.tracking.mixpanel.enabled && _.isFunction(window.mixpanel)) {
      const defaultEventProperties = {
        title: this.props.title,
        year: this.props.year,
        content_type: this.props.content_type
      }
      window.mixpanel.track(title, properties || defaultEventProperties)
    }
  }

  toggleModal = () => {
    this.props.loadRGPopupData(this.props.content_type, this.props.id)
    const isOpen = this.state.popupOpen

    if (isOpen) {
      this.setState({
        preselectedServiceGroupId: undefined,
        preselectedServiceGroupName: undefined
      })
    } else {
      this.trackEvent(this.state.preselectedServiceGroupName
        ? `CLICK_GROUP_${this.state.preselectedServiceGroupName.toUpperCase()}`
        : 'CLICK_MORE_OPTIONS'
      )
    }

    document.body.style.overflow = isOpen ? null : 'hidden'

    this.setState({
      popupOpen: !isOpen
    })
  }

  openService = (service, contenType, id, serviceIndex) => {
    const { behavior: { services: { group_services } } } = this.props.settings

    const prioIndex = _.indexOf(group_services
      ? this.props.settings.behavior.services.service_groups_prioritization
      : this.props.settings.behavior.services.service_platforms_prioritization
    , group_services ? service.group_id : service.id) + 1 // Pablo: ARRAYS START AT ONE.... Stupid mathematicians

    const trackingProperties = {
      popup: this.state.popupOpen,
      style: this.props.settings.appearance.general.style,
      theme: this.props.settings.appearance.general.theme,
      header: this.props.settings.appearance.header.text_visible,
      grouped: group_services,
      prio: prioIndex > 0 ? prioIndex : undefined,
      pos: serviceIndex + 1
    }

    this.props.openServiceLink(service.id, contenType, id, trackingProperties)
    this.trackEvent(`CLICK_${service.name.toUpperCase()}`)
  }

  itemSelected = (service, index = undefined) => {
    const {
      settings: {
        behavior: {
          services: { group_services }
        }
      },
      content_type,
      rg_id,
      ungrouped_availability
    } = this.props

    if (group_services && service.access_types.length > 1) {
      this.setState({
        preselectedServiceGroupId: service.id,
        preselectedServiceGroupName: service.name
      })

      this.toggleModal()
    } else {
      if (group_services && service.access_types.length === 1) {
        this.openService(
          _.find(ungrouped_availability, { group_id: service.id }),
          content_type,
          rg_id,
          index
        )
      } else {
        this.openService(service, content_type, rg_id, index)
      }
    }
  }

  getWidgetComponent() {
    return (
      <Widget
        state_id={this.props.state_id}
        id_type={this.props.id_type}
        toggleModal={this.toggleModal}
        itemSelected={this.itemSelected}
        trackEvent={this.trackEvent}
      />
    )
  }

  getPopupComponent() {
    if (!this.state.popupOpen) {
      return null
    }

    return (
      <Popup
        state_id={this.props.state_id}
        toggleModal={this.toggleModal}
        openService={this.openService}
        preselectedServiceGroupId={this.state.preselectedServiceGroupId}
        preselectedServiceGroupName={this.state.preselectedServiceGroupName}
        trackEvent={this.trackEvent}
      />
    )
  }

  render() {
    return (
      <span>
        {this.getWidgetComponent()}
        {this.getPopupComponent()}
      </span>
    )
  }
}
