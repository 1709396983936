import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import _ from 'lodash-es'
import classnames from 'classnames'

export default class Dropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    itemSelected: PropTypes.func,
    items: PropTypes.array,
    closeDropdown: PropTypes.func
  }

  stopEventPropagation = (e) => {
    e.stopPropagation()
  }

  render() {
    if (!this.props.items) {
      return null
    }

    const {
      appearance: {
        general: { theme },
        popup: {
          dropdown: { item_title_text_color, item_subtitle_text_color, open_bg_color }
        }
      }
    } = this.context.settings

    return (
      <span>
        <div 
          className={styles.background}
          onClick={this.props.closeDropdown}
          role='button'
          tabIndex='0'
        />
        <div
          className={styles.wrapper}
          role='button'
          tabIndex='0'
          onClick={this.stopEventPropagation}
          style={{
            backgroundColor: open_bg_color
          }}
        >
          {_.map(this.props.items, (item, index) => {
            return (
              <div className={classnames(styles.item, styles[theme])} role='button' tabIndex='0' onClick={() => this.props.itemSelected(index)} >
                <span
                  className={styles.title}
                  style={{
                    color: item_title_text_color
                  }}
                >
                  {item.title}
                </span>
                {item.subtitle &&
                  <span
                    className={styles.subtitle}
                    style={{
                      color: item_subtitle_text_color
                    }}
                  >
                    {item.subtitle}
                  </span>}
              </div>
            )
          })}
        </div>
      </span>
    )
  }
}
