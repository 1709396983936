import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import RowService from './rowService'
import RowServicePlatform from './rowServicePlatform'
import Seperator from './seperator'
import _ from 'lodash-es'

export default class RowServices extends Component {
  static propTypes = {
    max: PropTypes.number,
    services: PropTypes.array.isRequired,
    quantityLabel: PropTypes.string,
    accessType: PropTypes.string,
    itemSelected: PropTypes.func,
    platformSelection: PropTypes.bool,
    showPricingUnavailable: PropTypes.bool
  }

  pricingLabel = (service) => {
    switch (this.props.accessType) {
      case 0: return 'Free w/ Ads'
      case 1: return 'Included w/ TV Everywhere'
      case 2: return 'Included w/ Subscription'
      case 3:
        const { pricing: { rent, buy } } = service
        return (rent && `Rent from $${rent}`) || ((buy || !this.props.showPricingUnavailable) ? '' : 'Pricing Unavailable')
      default: return ''
    }
  }

  buyLabel = (service) => {
    if (this.props.accessType === 3) {
      const { pricing: { buy } } = service
      return service.pricing.buy && `Buy from $${buy}`
    }

    return undefined
  }

  render() {
    const { services, quantityLabel, platformSelection } = this.props

    const {
      behavior: {
        services: { group_services, service_groups_prioritization, service_platforms_prioritization }
      }
    } = this.context.settings

    let displayServices = [...services]

    if (group_services && !platformSelection && service_groups_prioritization && service_groups_prioritization.length) {
      displayServices = _.sortBy(displayServices, (service) => {
        const groupIndex = _.indexOf(service_groups_prioritization, group_services ? service.id : service.group_id)
        return (groupIndex === -1) ? Number.MAX_SAFE_INTEGER : groupIndex
      }) // eslint-disable-line array-func/prefer-array-from
    } else if ((!group_services || platformSelection) && service_platforms_prioritization && service_platforms_prioritization.length) {
      displayServices = _.sortBy(displayServices, (service) => {
        const platformIndex = _.indexOf(service_platforms_prioritization, service.id)
        return (platformIndex === -1) ? Number.MAX_SAFE_INTEGER : platformIndex
      }) // eslint-disable-line array-func/prefer-array-from
    }

    return (
      <div className={styles.services}>
        {displayServices
          .map((data, index) => {
            return [
              platformSelection
                ? (
                  <RowServicePlatform
                    id={data.id}
                    display_name={data.name}
                    itemSelected={() => this.props.itemSelected(data, index)}
                  />
                ) : (
                  <RowService
                    id={data.id}
                    logo_slug={data.logo_slug}
                    display_name={data.name}
                    quantity={data.quantity}
                    quantityLabel={quantityLabel}
                    pricingLabel={this.pricingLabel(data)}
                    buyLabel={this.buyLabel(data)}
                    itemSelected={() => this.props.itemSelected(data, index)}
                  />
                ),
              <Seperator short={platformSelection} />
            ]
          })}
      </div>
    )
  }
}
