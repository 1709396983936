import _ from 'lodash-es'
import { entityLoadSuccess, ENTITY_LOAD_FAIL, entityLookupSuccess, ENTITY_LOOKUP_FAIL, addEntities, entityLinkSuccess } from '~actions/entities'
import { epic } from '@reelgood/redux'
import { catchError, map, takeUntil, filter } from 'rxjs/operators'
import { constructStateId } from '~lib/state'
import { normalizeEntity } from './helpers'
import { pathEq } from 'ramda'

export const onEntityLoad = epic({
  key: 'onEntityLoad',
  onActions: ['ENTITY_LOAD'],
  callback: function onEntityLoad({ payload: { id, type } }, state$, { sdk }) {
    const parentStateId = constructStateId(type.replace('season', 'show'), id)

    return (
      sdk[`js_${type}`].details(...[id].filter(Boolean)).pipe(
        takeUntil(
          this.action$.ofType(entityLoadSuccess.toString()).pipe(
            filter(pathEq(['payload', 'id'], id))
          )
        ),
        map((entity) => {
          const entities = {
            [parentStateId]: normalizeEntity[type]({
              ...entity,
              overview: _.unescape(entity.overview),
              unwatched_count: entity.unwatched
            })
          }

          const entityMeta = {
            [parentStateId]: {
              loadState: type === 'complete'
            }
          }

          return [
            addEntities(entities, entityMeta),
            entityLoadSuccess(type, id, parentStateId, { loadState: type === 'complete' })
          ]
        }),
        catchError(err => {
          console.error('entity load failed!', err)
          return [{ type: ENTITY_LOAD_FAIL, payload: { type, id, code: err.statusCode, stateId: parentStateId } }]
        })
      )
    )
  }
})

export const onEntityLookup = epic({
  key: 'onEntityLookup',
  onActions: ['ENTITY_LOOKUP'],
  callback: function onEntityLookup({ payload: { id, id_type, type } }, state$, { sdk }) {
    return (
      sdk[`js_${type}`].lookup(...[id, id_type].filter(Boolean)).pipe(
        map((entity) => {
          const parentStateId = constructStateId(type.replace('season', 'show'), entity.id)

          const entities = {
            [parentStateId]: normalizeEntity[type]({
              ...entity,
              overview: _.unescape(entity.overview),
              unwatched_count: entity.unwatched
            })
          }

          const entityMeta = {
            [parentStateId]: {
              loadState: type === 'complete'
            }
          }

          return [
            addEntities(entities, entityMeta),
            entityLookupSuccess(type, id, id_type, entity.id),
            entityLoadSuccess(type, id, parentStateId, { loadState: type === 'complete' })
          ]
        }),
        catchError(err => {
          console.error('entity lookup failed!', err)
          return [{ type: ENTITY_LOOKUP_FAIL, payload: { type, id, id_type, code: err.statusCode } }]
        })
      )
    )
  }
})

export const onEntityLink = epic({
  key: 'onEntityLink',
  onActions: ['ENTITY_LINK'],
  callback: function onEntityLink({ payload: { type, id, service_id, tracking_properties = {} } }, state$, { sdk }) {
    let serviceLink = sdk[`js_${type}`].link(id, service_id)

    const customerInfoStr = encodeURIComponent(
      window.btoa(
        unescape(
          JSON.stringify(tracking_properties)
        )
      )
    )
    if (customerInfoStr) {
      serviceLink += `&ci=${customerInfoStr}`
    }

    window.open(serviceLink, '_blank')

    return [
      entityLinkSuccess(type, id, service_id)
    ]
  }
})
