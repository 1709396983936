import { Component } from 'preact'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import classnames from 'classnames'

export default class AccessType extends Component {
  static propTypes = {
    accessType: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    children: PropTypes.any,
    typeSelected: PropTypes.func,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool
  }

  typeSelected = () => {
    if (!this.props.disabled) {
      this.props.typeSelected(this.props.index)
    }
  }

  render() {
    if (this.props.accessType < 0 || this.props.accessType > 3) {
      return null
    }

    const { isSelected, disabled } = this.props

    const {
      appearance: {
        general: { theme },
        popup: {
          access_types: {
            bg_color,
            selected_bg_color,
            text_color,
            selected_text_color,
            border_color,
            selected_border_color
          }
        }
      }
    } = this.context.settings

    return (
      <div
        role='button'
        tabIndex='0'
        onClick={this.typeSelected}
        className={classnames(styles.wrapper,
          {
            [styles[theme]]: !isSelected && !disabled
          }
        )}
        style={{
          backgroundColor: isSelected ? selected_bg_color : bg_color,
          color: isSelected ? selected_text_color : text_color,
          borderColor: isSelected ? selected_border_color : border_color,
          cursor: (!isSelected && !disabled && 'pointer') || null
        }}
      >
        <span className={styles.title}>
          {this.props.children}
        </span>
      </div>
    )
  }
}
