import CORSFacet from './corsFacet'
import { decorate, enumerable } from 'core-decorators'

export default class show extends CORSFacet {
  @enumerable
  @decorate(CORSFacet.ensureParamsAndCallback)
  lookup(id, id_type, { auth, ...query } = {}) {
    return this.corsTransport('', { url: `${this.parterAPIUrl}/lookup/show/`, query: { id, id_type, ...query } }, auth)
  }

  @enumerable
  @decorate(CORSFacet.ensureParamsAndCallback)
  details(id, { auth, ...query } = {}) {
    return this.corsTransport('', { url: `${this.parterAPIUrl}/show/${id}`, ...query }, auth)
  }

  @enumerable
  @decorate(CORSFacet.ensureParamsAndCallback)
  popup(id, { auth, ...query } = {}) {
    return this.corsTransport('', { url: `${this.parterAPIUrl}/show/${id}/popup`, ...query }, auth)
  }

  @enumerable
  @decorate(CORSFacet.ensureParamsAndCallback)
  season(id, season, { auth, ...query } = {}) {
    return this.corsTransport('', { url: `${this.parterAPIUrl}/show/${id}/popup/season/${season}`, ...query }, auth)
  }

  link(id, service_id) {
    return `${this.linkingUrl}/coupler?type=show&content_id=${id}&service_id=${service_id}&api_key=${this.apiKey}`
  }
}
