import { Provider } from 'preact-redux'
import { h } from 'preact'

import App from '~components/app'
import { normalizeSettings } from '~settings'

export default store => props => {
  let _props = props

  if (props.props) {
    const newProps = JSON.parse(props.props.replace(/'/g, '"'))
    _props = newProps
  }

  const settings = normalizeSettings(_props.settings)

  console.log('Init App with settings: ', {
    ..._props,
    settings
  })

  return (
    <Provider store={store}>
      {h(App, {
        ..._props,
        settings
      })}
    </Provider>
  )
}
