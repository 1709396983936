
import { loadSettings, loadSettingsSuccess, loadSettingsFail } from '~actions/settings'

const initialState = {
  hasLoaded: false,
  isLoading: false,
  failed: false,
  settings: {}
}

export default function settings(state = initialState, { type, payload }) {
  switch (type) {
    case loadSettings.toString():
      return { ...state, isLoading: true }
    case loadSettingsSuccess.toString():
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        settings: payload
      }
    case loadSettingsFail.toString():
      return {
        ...state,
        isLoading: false,
        failed: true
      }
    default:
      return state
  }
}
