import _ from 'lodash-es'

export const constructStateId = (type, id, scope, params = '') => (
  `${type}:${id || ''}:${params}@${scope || 'global'}`
)

const stateIdIndexMap = { 0: 'type', 1: 'id', 2: 'params', 3: 'scope' }

export const getDataFromStateId = _.memoize(stateId => (
  stateId.split(/[:@]/g).reduce((data, value, index) => {
    data[stateIdIndexMap[index]] = value
    return data
  }, {})
))
