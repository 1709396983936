import { Component } from 'preact'
import { connect } from 'preact-redux'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import _ from 'lodash-es'
import { entityByStateId, entityMetaByStateId } from '~selectors/entities'
import AccessType from './accessType'
import RowServices from '~components/services/rowServices'
import DropdownSelection from './dropdownSelection'
import { loadSeason } from '~actions/season'
import classnames from 'classnames'

const accessTypePriority = [2, 0, 1, 3]

const mapDispatchToProps = (dispatch, props) => ({
  seasonSelected: (type, id, season) => dispatch(loadSeason(type, id, season))
})

const mapStateToProps = (state, { state_id }) => {
  const entity = entityByStateId(state, state_id)[1]
  const entityMeta = entityMetaByStateId(state, state_id)[1]

  return {
    ...entity,
    ...entityMeta
  }
}

const titleForAccessType = (accessType) => {
  switch (accessType) {
    case 0: return 'FREE W/ ADS'
    case 1:
    case 2: 
      return 'SUBSCRIPTION & TVE'
    case 3: return 'RENT/BUY'
    default: return ''
  }
}

const emptyStateTitleForAccessType = (accessType) => {
  switch (accessType) {
    case 0: return 'free'
    case 1:
    case 2:
      return 'subscription or tv everywhere'
    case 3: return 'rent or buy'
    default: return ''
  }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class Popup extends Component {
  static propTypes = {
    rg_id: PropTypes.string,
    state_id: PropTypes.string,
    preselectedServiceGroupId: PropTypes.number,
    preselectedServiceGroupName: PropTypes.string,
    toggleModal: PropTypes.func,
    openService: PropTypes.func,
    trackEvent: PropTypes.func,

    // Entity data
    title: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    grouped_availability: PropTypes.array,
    ungrouped_availability: PropTypes.array,
    season: PropTypes.array,
    season_data: PropTypes.any
  }

  stopEventPropagation = (e) => {
    e.stopPropagation()
  }

  state = {
    selectedAccessType: 0,
    selectedSeason: undefined,
    selectedEpisode: undefined,
    selectedServiceGroupId: undefined,
    selectedServiceGroupName: undefined,
    dropdownOpen: false
  }

  accessTypes = () => {
    const { settings: { behavior: { services: { group_services } } } } = this.context
    const { grouped_availability, ungrouped_availability } = this.props

    const accessTypes = group_services
      ? _.flatMap(grouped_availability, 'access_types')
      : _.map(ungrouped_availability, 'access_type')

    let result = _.uniq(accessTypes)
    result = _.sortBy(result, (type) => _.indexOf(accessTypePriority, type))

    if (result.includes(1) && result.includes(2)) {
      return _.filter(result, (access_type) => access_type !== 1)
    }

    return result
  }

  serviceItems = () => {
    const accessType = this.accessTypes()[this.state.selectedAccessType]
    const combinedAccessTypes = [1, 2].includes(accessType) ? [1, 2] : []
    const accessTypes = [accessType, ...combinedAccessTypes]

    let grouped_availability = this.props.grouped_availability
    let ungrouped_availability = this.props.ungrouped_availability

    const { settings: { behavior: { services: { group_services } } } } = this.context
    const { type } = this.props

    if (type === 'show') {
      const { selectedSeason, selectedEpisode } = this.state

      if (selectedSeason !== undefined) {
        const { seasons, season_data } = this.props
        const season = season_data[seasons[selectedSeason].id]

        if (!season) {
          return []
        }

        const item = selectedEpisode !== undefined ? season.episodes[selectedEpisode] : season

        grouped_availability = item.grouped_availability
        ungrouped_availability = item.ungrouped_availability
      }
    }

    if (this.state.selectedServiceGroupId !== undefined && group_services) {
      return _.filter(ungrouped_availability, { group_id: this.state.selectedServiceGroupId })
    }

    return group_services
      ? _.filter(grouped_availability, (service) => _.intersection(service.access_types, accessTypes).length > 0)
      : _.filter(ungrouped_availability, (service) => accessTypes.includes(service.access_type))
  }

  typeSelected = (index) => {
    this.clearServiceGroupSelected()

    if (index !== this.state.selectedAccessType) {
      this.setState({
        selectedAccessType: index
      })
    }
  }

  seasonSelected = (index) => {
    const { seasons } = this.props

    if (!seasons || seasons.length === 0) {
      return
    }

    this.clearServiceGroupSelected()

    const { seasonSelected, type, rg_id } = this.props

    if (index - 1 !== this.state.selectedSeason) {
      this.setState({ selectedSeason: index > 0 ? index - 1 : undefined, selectedEpisode: undefined })

      if (index > 0) {
        seasonSelected(type, rg_id, seasons[index - 1].id)
      }
    }
  }

  episodeSelected = (index) => {
    const selectedSeason = this.props.seasons[this.state.selectedSeason]
    const seasonData = this.props.season_data[selectedSeason.id]

    if (!seasonData) {
      return
    }

    this.clearServiceGroupSelected()

    if (index - 1 !== this.state.selectedEpisode) {
      this.setState({ selectedEpisode: index > 0 ? index - 1 : undefined })
    }
  }

  clearServiceGroupSelected = () => {
    this.setState({
      selectedServiceGroupId: undefined,
      selectedServiceGroupName: undefined
    })
  }

  openService = (service, index) => {
    const { type, rg_id } = this.props

    if (type === 'movie') {
      this.props.openService(service, type, rg_id, index)
    } else {
      const { selectedSeason, selectedEpisode } = this.state

      if (!selectedSeason) {
        this.props.openService(service, type, rg_id, index)
        return
      }

      const { seasons, season_data } = this.props
      const season = season_data[seasons[selectedSeason].id]
      const item = selectedEpisode !== undefined ? season.episodes[selectedEpisode] : season

      this.props.openService(
        service,
        selectedEpisode !== undefined ? 'episode' : 'season',
        item.id,
        index
      )
    }
  }

  serviceSelected = (selectedService, index) => {
    const { settings: { behavior: { services: { group_services } } } } = this.context

    if (this.state.selectedServiceGroupId === undefined && group_services) {
      this.setState({
        selectedServiceGroupId: selectedService.id,
        selectedServiceGroupName: selectedService.name
      })

      if (selectedService.access_types.length === 1) {
        const selectedServices = this.serviceItems()

        if (selectedServices.length === 1) {
          this.clearServiceGroupSelected()
          this.openService(selectedServices[0], index)

          return
        }
      }

      this.props.trackEvent(`CLICK_GROUP_${selectedService.name.toUpperCase().replace(' ', '_')}`)
    } else {
      this.openService(selectedService, index)
    }
  }

  dropdownToggled = (isOpen, dropdownTitle) => {
    this.setState({
      dropdownOpen: isOpen ? dropdownTitle : false
    })
  }

  seasonDropdownItems = () => {
    if (!this.props.seasons || this.props.seasons.length === 0) {
      return [{
        title: 'Loading...'
      }]
    }

    return [
      {
        title: 'All',
        selected: 'ALL'
      },
      ..._.map(this.props.seasons, (season) => {
        return {
          title: `Season ${season.number}`,
          selected: season.number
        }
      })
    ]
  }

  episodeDropdownItems = () => {
    const selectedSeason = this.props.seasons[this.state.selectedSeason]

    if (!selectedSeason) {
      return []
    }

    const seasonData = this.props.season_data[selectedSeason.id]

    if (!seasonData) {
      return [{
        title: 'Loading...'
      }]
    }

    return [
      {
        title: 'All',
        selected: 'ALL'
      },
      ..._.map(seasonData.episodes, (episode) => {
        return {
          title: `${episode.number}.`,
          subtitle: episode.title,
          selected: episode.number
        }
      })
    ]
  }

  componentWillMount() {
    if (this.props.preselectedServiceGroupId && this.props.preselectedServiceGroupName) {
      this.setState({
        selectedServiceGroupId: this.props.preselectedServiceGroupId,
        selectedServiceGroupName: this.props.preselectedServiceGroupName
      })
    }
  }

  render() {
    const { title, type, slug, isLoading } = this.props
    const { selectedSeason, selectedEpisode, selectedServiceGroupId, selectedServiceGroupName } = this.state

    const rgUrl = `https://www.reelgood.com/${(type && slug && `${type}/${slug}`) || ''}`
    const {
      appearance: {
        popup: {
          general: { bg_color, close_icon_color, border_color, empty_state_color },
          header: { color }
        }
      },
      behavior: {
        services: { group_services }
      }
    } = this.context.settings

    const isShow = type === 'show'

    const accessTypes = this.accessTypes()
    const selectedAccessType = accessTypes[this.state.selectedAccessType]
    const serviceItems = this.serviceItems()

    const quantityLabel = isShow && (selectedSeason !== undefined
      ? selectedEpisode !== undefined
        ? undefined
        : 'Episode'
      : 'Season')

    const isActuallyEmpty = (!serviceItems.length && !title && !isLoading)

    const emptyState = isActuallyEmpty
      ? (
        <span style={{color: empty_state_color}}>
          Where to Watch Widget Failed To Load.<br />Please Try Again.
        </span>
      )
      : (!serviceItems.length && !isLoading)
        ? (
          <div className={styles.emptyState} style={{color: empty_state_color}}>
            No {emptyStateTitleForAccessType(selectedAccessType)} Streaming Services Found Get Notified When It's Available, <a target='_blank' href={rgUrl}>Watchlist it on Reelgood</a>
          </div>
        )
        : null

    return (
      <div className={styles.popup} role='button' tabIndex='0' onClick={this.props.toggleModal} >
        <div
          className={classnames(
            styles.content,
            {
              [styles.dropdownOpen]: this.state.dropdownOpen
            }
          )}
          role='button'
          tabIndex='0'
          onClick={this.stopEventPropagation}
          style={{
            backgroundColor: bg_color,
            borderColor: border_color
          }}
        >
          <div className={styles.header}>
            <div className={styles.titleBar}>
              <span
                className={styles.title}
                style={{
                  color: color
                }}
              >
                {title}
              </span>
              <div className={styles.close} role='button' tabIndex='0' onClick={this.props.toggleModal}>
                <svg viewBox="0 0 15 16" width="16" height="16" stroke={close_icon_color} stroke-width="2">
                  <path d="M-0.80440598,8 L17.3289274,8" id="Line" transform="translate(8.262261, 8.000000) rotate(-315.000000) translate(-8.262261, -8.000000) " />
                  <path d="M-0.896296323,8 L17.237037,8" id="Line" transform="translate(8.170370, 8.000000) scale(-1, 1) rotate(-315.000000) translate(-8.170370, -8.000000) " />
                </svg>
              </div>
            </div>
            {
              !isActuallyEmpty && isShow && (
                <div className={styles.seasonEpisodeSelectors}>
                  <DropdownSelection
                    title={'SEASON'}
                    items={this.seasonDropdownItems()}
                    itemSelected={this.seasonSelected}
                    selectedItem={selectedSeason + 1}
                    disabled={this.state.dropdownOpen && this.state.dropdownOpen !== 'SEASON'}
                    dropdownToggled={this.dropdownToggled}
                  />

                  { this.state.selectedSeason !== undefined && (
                    <DropdownSelection
                      title={'EPISODE'}
                      items={this.episodeDropdownItems()}
                      itemSelected={this.episodeSelected}
                      selectedItem={selectedEpisode + 1}
                      disabled={this.state.dropdownOpen && this.state.dropdownOpen !== 'EPISODE'}
                      dropdownToggled={this.dropdownToggled}
                    />
                  )}
                </div>
              )
            }

            {!isActuallyEmpty && (
              <div className={styles.accessTypes}>
                <div className={styles.container}>
                  {_.map(accessTypes, (accessType, index) => (
                    <AccessType
                      typeSelected={this.typeSelected}
                      accessType={accessType}
                      isSelected={this.state.selectedAccessType === index}
                      index={index}
                    >
                      {titleForAccessType(accessType)}
                    </AccessType>
                  ))}
                </div>
              </div>
            )}

            {
              selectedServiceGroupId !== undefined && (
                <div className={styles.serviceGroupSelected}>
                  <svg viewBox="-21 -9 10 19" stroke-width="2" stroke="#00BD76" fill="none" alt='Close Icon' onClick={this.clearServiceGroupSelected}>
                    <polyline id="Path-15-Copy-18" transform="scale(-1, 1) rotate(-45.000000)" points="2.485281 15.485281 13.315495 14.315495 14.485281 3.485281" />
                  </svg>
                  Choose an option for {selectedServiceGroupName}
                </div>
              )
            }
          </div>

          {emptyState}

          {!emptyState && (
            <div
              className={styles.services}
              style={{
                borderTopColor: border_color
              }}
            >
              <RowServices
                services={serviceItems}
                quantityLabel={quantityLabel}
                accessType={selectedAccessType}
                itemSelected={this.serviceSelected}
                platformSelection={selectedServiceGroupId !== undefined}
                showPricingUnavailable={(selectedSeason !== undefined && selectedEpisode !== undefined) || type === 'movie'}
              />
            </div>
          )}

          <div
            className={styles.footer}
            style={{
              backgroundColor: bg_color,
              borderTopColor: border_color
            }}
          >
            <a className={styles.poweredBy} target='_blank' href={rgUrl}>
              <svg width="18px" height="18px" viewBox="0 0 60 60">
                <defs>
                  <radialGradient cx="42.6890365%" cy="54.3550515%" fx="42.6890365%" fy="54.3550515%" r="117.629891%" gradientTransform="translate(0.426890,0.543551),scale(1.000000,0.865385),rotate(44.100468),translate(-0.426890,-0.543551)" id="radialGradient-5">
                    <stop stop-color="#66FFC5" offset="0%" />
                    <stop stop-color="#00E08C" offset="100%" />
                  </radialGradient>
                  <radialGradient cx="42.6890365%" cy="54.3550515%" fx="42.6890365%" fy="54.3550515%" r="117.571086%" gradientTransform="translate(0.426890,0.543551),scale(1.000000,0.866279),rotate(44.070888),translate(-0.426890,-0.543551)" id="radialGradient-6">
                    <stop stop-color="#66FFC5" offset="0%" />
                    <stop stop-color="#00E08C" offset="100%" />
                  </radialGradient>
                </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <mask id="mask-2" fill="white">
                    <path d="M17.0895772,-1.59727351e-15 L42.9104228,6.90439374e-16 C48.8528411,-4.0116516e-16 51.0077056,0.618729126 53.1801649,1.78057308 C55.3526242,2.94241704 57.057583,4.64737582 58.2194269,6.81983513 C59.3812709,8.99229444 60,11.1471589 60,17.0895772 L60,42.9104228 C60,48.8528411 59.3812709,51.0077056 58.2194269,53.1801649 C57.057583,55.3526242 55.3526242,57.057583 53.1801649,58.2194269 C51.0077056,59.3812709 48.8528411,60 42.9104228,60 L17.0895772,60 C11.1471589,60 8.99229444,59.3812709 6.81983513,58.2194269 C4.64737582,57.057583 2.94241704,55.3526242 1.78057308,53.1801649 C0.618729126,51.0077056 7.9434019e-16,48.8528411 -1.36712706e-15,42.9104228 L4.60292916e-16,17.0895772 C-2.6744344e-16,11.1471589 0.618729126,8.99229444 1.78057308,6.81983513 C2.94241704,4.64737582 4.64737582,2.94241704 6.81983513,1.78057308 C8.99229444,0.618729126 11.1471589,9.28061909e-16 17.0895772,-1.59727351e-15 Z" id="path-1" />
                  </mask>
                  <g id="Mask" />
                  <g id="Icon" mask="url(#mask-2)">
                    <rect fill="#081118" id="path-4" x="0" y="0" width="60" height="60" />
                    <linearGradient fill-opacity="0.16" x1="0%" y1="6.24500451e-15%" x2="101.999998%" y2="100.999999%" id="linearGradient-3">
                      <stop stop-color="#79CFF9" offset="0%" />
                      <stop stop-color="#71FF97" offset="100%" />
                    </linearGradient>
                    <g id="Group" stroke-width="1" transform="translate(11.718750, 12.539062)" stroke="#00DC89">
                      <path d="M28.3256399,22.8632481 C30.6380893,21.9459449 33.0159854,19.5855904 33.0159854,15.5708141 C33.0159854,10.6737005 29.5684945,7.3828125 24.4380438,7.3828125 L21.669997,7.3828125 L21.669997,13.0327951 L23.5018717,13.0327951 C24.8753035,13.0327951 26.265176,13.8801664 26.265176,15.4989923 C26.265176,17.1178181 24.8753035,17.9651895 23.5018717,17.9651895 L18.8304963,17.9651895 L18.8304963,14.4500158 L12.1875,18.0370113 L12.1875,31.7578125 L18.8304963,31.7578125 L18.8304963,23.651083 L21.6339539,23.651083 L25.6663547,31.7578125 L33.28125,31.7578125 L28.3256399,22.8632481 Z" id="Fill-10" stroke-width="0.5" fill="url(#radialGradient-5)" />
                      <path d="M0,0 L17.4609375,10.078125 L0,20.15625 L0,0 Z M2.37175581,9.28776412 L3.97025684,9.28776412 L3.97025684,13.2874501 L11.0253402,9.10127801 L2.37175581,4.10647446 L2.37175581,9.28776412 Z" id="Fill-12" stroke-width="0.5" fill="url(#radialGradient-6)" />
                    </g>
                  </g>
                </g>
              </svg>
              Powered by Reelgood
            </a>
          </div>
        </div>
      </div>
    )
  }
}
